import React from "react";
import { Table as AntdTable, Flex, Pagination } from "antd";

import styles from "./styles.module.scss";

const Table = ({
  columns,
  data,
  total = 0,
  onChange,
  pageSize = 10,
  currentPage = 1,
}) => {
  return (
    <Flex vertical>
      <AntdTable
        className={styles.container}
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={(_, ...rest) => onChange?.(undefined, ...rest)}
      />
      <Flex justify="end" className="pt-6">
        <Pagination
          total={total}
          defaultCurrent={1}
          pageSize={pageSize}
          current={currentPage}
          onChange={(page, newPageSize) => onChange?.(page, newPageSize)}
        />
      </Flex>
    </Flex>
  );
};

export default Table;
