import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESS_TOKEN_KEY, EMAIL_KEY, NAME_KEY } from "constants/values";
import requestHelpers from "helpers/request-helper";

import authServices from "services/auth.services";

const initialState = {
  loginForm: undefined,
};

export const sendVerificationCode = createAsyncThunk(
  "auth/sendVerificationCode",
  async (body) => {
    try {
      const res = await authServices.sendVerificationCode(body);

      return res;
    } catch (error) {
      return error;
    }
  }
);

export const loginWithVerificationCode = createAsyncThunk(
  "auth/loginWithVerificationCode",
  async (body) => {
    try {
      const res = await authServices.loginWithVerificationCode(body);

      if (res?.access_token) {
        requestHelpers.setAuthorizationToken(res?.access_token);
        localStorage.setItem(ACCESS_TOKEN_KEY, res?.access_token);
        localStorage.setItem(EMAIL_KEY, body?.email);
        localStorage.setItem(NAME_KEY, res?.name);
      }

      return res;
    } catch (error) {
      return null;
    }
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginForm: (state, action) => {
      state.loginForm = action.payload;
    },
  },
});

export default AuthSlice;
