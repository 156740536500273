import React, { useState } from "react";
import { Button as AntdButton } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { useAppSelector } from "stores/stores";
import useBreakPoint from "hooks/useBreakpoint";

import AuthorizedLayout from "components/layouts/AuthorizedLayout";
import LoginForm from "./components/LoginForm";
import OtpForm from "./components/OtpForm";

const LoginPage = () => {
  const { loginForm } = useAppSelector((state) => state.auth);

  const [isOtpForm, setIsOtpForm] = useState(false);

  const { isMobile, isDesktop } = useBreakPoint();

  return (
    <AuthorizedLayout
      hideLogo={isOtpForm}
      title={isOtpForm ? "Enter the verification code" : "Welcome back"}
      description={
        isOtpForm
          ? `Please check your inbox for verification code sent to ${loginForm?.email}`
          : "Log in using your email to continue."
      }
      className={
        isOtpForm
          ? "max-w-[90vw] w-full md:m-fit md:max-w-[464px]"
          : "max-w-[90vw] w-full md:m-fit md:max-w-[350px]"
      }
      action={
        isMobile &&
        isOtpForm && (
          <AntdButton
            icon={<ArrowLeftOutlined />}
            ghost
            type="text"
            onClick={() => setIsOtpForm(false)}
          />
        )
      }
      center={isDesktop ? !isOtpForm : true}
      isBack={isDesktop && isOtpForm}
      onBack={() => setIsOtpForm(false)}>
      {isOtpForm ? <OtpForm /> : <LoginForm onIsOtpFormChange={setIsOtpForm} />}
    </AuthorizedLayout>
  );
};

export default LoginPage;
