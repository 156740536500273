import { Form, message } from "antd";
import React, { useCallback } from "react";

import AuthSlice, { sendVerificationCode } from "stores/authSlice";
import { useAppDispatch } from "stores/stores";
import { validateMessages } from "./constants";

import Button from "components/atoms/Button";
import InputField from "components/atoms/InputField";
import { EmailIcon } from "assets/icons";

const LoginForm = ({ onIsOtpFormChange }) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async (data) => {
      const { payload } = await dispatch(sendVerificationCode(data));

      if (payload?.data?.message) {
        message.error(payload?.data?.message);
      } else {
        onIsOtpFormChange?.(true);
        dispatch(AuthSlice.actions.setLoginForm(data));
      }
    },
    [dispatch, onIsOtpFormChange]
  );

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      requiredMark={false}>
      <Form.Item
        label={<span className="font-medium text-dark1">Email</span>}
        name="email"
        rules={[
          {
            required: true,
            message: "Email is required",
          },
          {
            type: "email",
          },
        ]}>
        <InputField prefix={<EmailIcon />} />
      </Form.Item>
      <Button className="!h-[48px] !w-full" htmlType="submit">
        Continue
      </Button>
    </Form>
  );
};

export default LoginForm;
