import React, { useMemo } from "react";

import { PlaneIcon } from "assets/icons";

import { useAppSelector } from "stores/stores";

import ResultInfoItem from "components/atoms/ResultInfoItem";

const BoardingPassInformation = () => {
  const { verificationDetail } = useAppSelector((state) => state.issuer);

  const boardingPassInfos = useMemo(() => {
    if (!verificationDetail?.infomation?.boardingpasses?.length) {
      return;
    }

    return verificationDetail?.infomation?.boardingpasses?.map((record) => [
      {
        label: "Passenger name",
        value: record?.passengerName,
      },
      {
        label: "Departure Airport",
        value: record?.fromAirportCode,
      },
      {
        label: "Arrival airport",
        value: record?.toAirportCode,
      },
      {
        label: "Flight Number",
        value: record?.flightNumber,
      },
    ]);
  }, [verificationDetail?.infomation?.boardingpasses]);

  return (
    boardingPassInfos && (
      <div className="flex flex-col gap-6">
        {boardingPassInfos?.map((record, index) => (
          <ResultInfoItem
            key={`boarding-pass-info-${index}`}
            title="Boarding Pass"
            subTitle="Basic Credential"
            icon={<PlaneIcon />}
            infos={record}
            col={2}
          />
        ))}
      </div>
    )
  );
};

export default BoardingPassInformation;
