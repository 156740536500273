import END_POINTS from "constants/endpoints";
import requestHelpers from "helpers/request-helper";

const authServices = {
  sendVerificationCode: async (params) => {
    return requestHelpers.get(END_POINTS.sendVerificationCode, params);
  },
  loginWithVerificationCode: async (body) => {
    return requestHelpers.post(END_POINTS.loginWithVerificationCode, body);
  },
};

export default authServices;
