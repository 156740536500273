import { useCallback, useState } from "react";

const useSort = (defaultSort) => {
  const [sorter, setSorter] = useState(defaultSort);

  const handleSortChange = useCallback((sortOptions) => {
    setSorter({
      sortField: sortOptions?.field,
      sortOrder: sortOptions?.order === "descend" ? "DESC" : "ASC",
    });
  }, []);

  return {
    sorter,
    onSortChange: handleSortChange,
  };
};

export default useSort;
