import { RequestHelpers } from "services";
import CommonSlice from "stores/commonSlice";
import store from "stores/stores";

const requestHelpers = new RequestHelpers({
  incrementLoading: () => {
    store.dispatch(CommonSlice.actions.incrementLoading());
  },
  decrementLoading: () => {
    store.dispatch(CommonSlice.actions.decrementLoading());
  },
});

export default requestHelpers;
