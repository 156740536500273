import { Input } from "antd";
import React from "react";

import styles from "./styles.module.scss";

const InputField = ({ prefix, ...rest }) => {
  return (
    <Input className={`px-4 py-3 ${styles.input}`} prefix={prefix} {...rest} />
  );
};

export default InputField;
