import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Image } from "antd";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import trashImage from "assets/images/trash.png";

import ROUTES from "constants/routes";
import { toCredentialDetail } from "helpers/routing";
import { useAppDispatch, useAppSelector } from "stores/stores";
import useBreakPoint from "hooks/useBreakpoint";
import { getCredentialDetail, revokeCredential } from "stores/issuerSlice";

import MainLayout from "components/layouts/MainLayout";
import PageHeader from "components/layouts/PageHeader";
import GeneralInformation from "./components/GeneralInformation";
import DocumentInformation from "./components/DocumentInformation";
import NotificationModal from "components/atoms/molecules/NotificationModal";
import Button from "components/atoms/Button";

const CredentialDetailPage = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.issuer);

  const [openRevokeModal, setOpenRevokeModal] = useState(false);

  const { isMobile } = useBreakPoint();
  const { id } = useParams();
  const history = useHistory();

  const breadcrumbs = useMemo(
    () => [
      {
        title: "Credentials",
        href: ROUTES.credentials,
      },
      {
        title: id,
        href: toCredentialDetail({
          id,
          plain: true,
        }),
      },
    ],
    [id]
  );

  const handleOpenRevokeModal = useCallback(() => {
    setOpenRevokeModal(true);
  }, []);

  const handleRevokeCredential = useCallback(async () => {
    await dispatch(revokeCredential(id));

    history.push(ROUTES.credentials);
  }, [dispatch, history, id]);

  useEffect(() => {
    dispatch(getCredentialDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <MainLayout hideFooter>
        <PageHeader
          breadcrumbs={breadcrumbs}
          addText={!isMobile && "Revoke credential"}
          addProps={{
            variant: "outlined",
            type: "danger",
            loading,
          }}
          onAdd={handleOpenRevokeModal}
        />
        <div className="mt-6 flex gap-6 flex-col md:flex-row">
          <GeneralInformation />
          <DocumentInformation />
          {isMobile && (
            <Button
              loading={loading}
              type="danger"
              variant="outlined"
              className="mt-2"
              onClick={handleOpenRevokeModal}>
              Revoke credential
            </Button>
          )}
        </div>
      </MainLayout>

      <NotificationModal
        open={openRevokeModal}
        title="Revoking credential"
        description="Revoking this credential will permanently delete it from the user's Liquid wallet and Liquid portal"
        cancelText="Cancel"
        okText="Revoking"
        okProps={{
          type: "danger",
        }}
        onOk={handleRevokeCredential}
        onCancel={() => setOpenRevokeModal(false)}
        image={
          <Image preview={false} src={trashImage} alt="Trash" width={160} />
        }
      />
    </>
  );
};

export default CredentialDetailPage;
