export const FORMAT_DATE = "DD/MM/YYYY";
export const FORMAT_DATE_FULL = "DD/MM/YYYY hh:mm";
export const FORMAT_DATE_DISPLAY = "HH:mm, DD/MM/YYYY";

export const ACCESS_TOKEN_KEY = "access_token";
export const EMAIL_KEY = "email";
export const NAME_KEY = "name";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again!";

export const KEY_IGNORE_VALIDATE = ["user_does_not_exist"];
