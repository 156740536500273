import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Typography } from "antd";

import { ScanIcon } from "assets/icons";

import useBreakPoint from "hooks/useBreakpoint";
import { useAppDispatch, useAppSelector } from "stores/stores";
import IssuerSlice, { getVerifications } from "stores/issuerSlice";
import usePagination from "hooks/usePagination";
import { FORMAT_DATE_FULL } from "constants/values";
import { formatVerificationStatus } from "helpers/commons";
import useSort from "hooks/useSort";
import { toVerificationDetail } from "helpers/routing";

import MainLayout from "components/layouts/MainLayout";
import PageHeader from "components/layouts/PageHeader";
import Table from "components/atoms/Table";
import BadgeStatus from "components/atoms/BadgeStatus";
import ScanVerifyModal from "./components/ScanVerifyModal";
import VerificationsList from "./components/VerificationsList";

const VerificationsPage = () => {
  const dispatch = useAppDispatch();
  const { verifications } = useAppSelector((state) => state.issuer);

  const [openScanVerifyModal, setOpenScanVerifyModal] = useState(false);

  const { isMobile } = useBreakPoint();
  const { paginationFilter, onChangePage, page, pageSize, onChangePageSize } =
    usePagination({
      total: verifications?.total,
      limit: 20,
    });
  const { sorter, onSortChange } = useSort({
    sortField: "createdAt",
    sortOrder: "DESC",
  });

  const columns = [
    {
      title: "Presentation ID",
      dataIndex: "id",
      render: (value) => (
        <Link
          to={toVerificationDetail({
            id: value,
            plain: true,
          })}
          component={Typography.Link}>
          {value}
        </Link>
      ),
    },
    {
      title: "Verification date & time",
      key: "createdAt",
      dataIndex: "createdAt",
      showSorterTooltip: { target: "full-header" },
      sorter: true,
      render: (_, record) =>
        dayjs(record?.verificationDateAndTime).format(FORMAT_DATE_FULL),
    },
    {
      title: "Verification result",
      dataIndex: "status",
      render: (value) => (
        <BadgeStatus status={formatVerificationStatus(value)} text={value} />
      ),
    },
  ];

  const handleCloseScanVerifyModal = useCallback(() => {
    dispatch(IssuerSlice.actions.setPresentationId(""));
    dispatch(IssuerSlice.actions.setVerificationResult(undefined));
    setOpenScanVerifyModal(false);
  }, [dispatch]);

  const handleTableChange = useCallback(
    (pagination, newPageSize, sort) => {
      if (pagination) {
        onChangePage?.(pagination);
      }

      if (sort) {
        onSortChange(sort);
      }

      if (newPageSize) {
        onChangePageSize?.(newPageSize);
      }
    },
    [onChangePage, onChangePageSize, onSortChange]
  );

  useEffect(() => {
    dispatch(getVerifications({ ...paginationFilter, ...sorter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationFilter, sorter]);

  return (
    <MainLayout>
      <PageHeader
        title="Verifications"
        subTitle="This one-time process scans a holder’s DID to verify the DTC validity and reveals limited information as the holder approves the request."
        addText="Scan & Verify"
        addIcon={<ScanIcon color="#fff" />}
        onAdd={() => setOpenScanVerifyModal(true)}
      />
      <div className="mt-6">
        {isMobile ? (
          <VerificationsList />
        ) : (
          <Table
            columns={columns}
            data={verifications?.data}
            onChange={handleTableChange}
            total={verifications?.total}
            currentPage={page}
            pageSize={pageSize}
          />
        )}
      </div>
      <ScanVerifyModal
        open={openScanVerifyModal}
        onOpen={() => setOpenScanVerifyModal(true)}
        onCancel={handleCloseScanVerifyModal}
      />
    </MainLayout>
  );
};

export default VerificationsPage;
