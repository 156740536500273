import ROUTES from "constants/routes";
import history from "./history";

export const toLogin = (options = {}) => {
  const { replace, original } = options;

  const path = ROUTES.login;

  if (original) {
    return path;
  }

  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }

  return undefined;
};

export const toVerifications = (options = {}) => {
  const { replace, original } = options;

  const path = ROUTES.verifications;

  if (original) {
    return path;
  }

  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }

  return undefined;
};

export const toCredentials = (options = {}) => {
  const { replace, original } = options;

  const path = ROUTES.credentials;

  if (original) {
    return path;
  }

  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }

  return undefined;
};

export const toCredentialDetail = (options = {}) => {
  const { id, replace, plain, original } = options;

  if (original) {
    return ROUTES.credentialDetail;
  }

  const path = `${ROUTES.credentials}/${id}`;

  if (plain) {
    return path;
  }

  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }

  return undefined;
};

export const toVerificationDetail = (options = {}) => {
  const { id, replace, plain, original } = options;

  if (original) {
    return ROUTES.verificationDetail;
  }

  const path = `${ROUTES.verifications}/${id}`;

  if (plain) {
    return path;
  }

  if (replace) {
    history.replace(path);
  } else {
    history.push(path);
  }

  return undefined;
};
