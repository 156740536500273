import COLORS from "constants/colors";
import React from "react";

export default function ScanIcon(props) {
  const { color = COLORS.grey1 } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9H1.01M5 9H5.01M13 9H13.01M9 9H9.01M17 9H17.01M5.5 1H4.2C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V5.5M12.5 1H13.8C14.9201 1 15.4802 1 15.908 1.21799C16.2843 1.40973 16.5903 1.71569 16.782 2.09202C17 2.51984 17 3.07989 17 4.2V5.5M17 12.5V13.8C17 14.9201 17 15.4802 16.782 15.908C16.5903 16.2843 16.2843 16.5903 15.908 16.782C15.4802 17 14.9201 17 13.8 17H12.5M1 12.5V13.8C1 14.9201 1 15.4802 1.21799 15.908C1.40973 16.2843 1.71569 16.5903 2.09202 16.782C2.51984 17 3.0799 17 4.2 17H5.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
