import React from "react";
import { Breadcrumb as AntdBreadcrumb, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import useBreakPoint from "hooks/useBreakpoint";

import styles from "./styles.module.scss";

const Breadcrumb = ({ items }) => {
  const { isMobile } = useBreakPoint();
  const history = useHistory();

  if (isMobile) {
    return (
      <div className="flex items-center gap-6 self-start">
        <Button
          icon={<ArrowLeftOutlined />}
          ghost
          type="text"
          onClick={() => history.goBack()}
        />
        <span className="text-dark1 text-xl font-semibold">
          {items?.pop()?.title}
        </span>
      </div>
    );
  }

  return (
    <AntdBreadcrumb separator=">" items={items} className={styles.container} />
  );
};

export default Breadcrumb;
