import axios from "axios";
import ROUTES from "constants/routes";

import { ACCESS_TOKEN_KEY, KEY_IGNORE_VALIDATE } from "constants/values";

const baseApiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseApiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    apikey: process.env.NEXT_PUBLIC_API_KEY,
  },
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  //@ts-ignore
  config.headers = {
    ...config.headers,
    Authorization: token ? `Bearer ${token}` : "",
  };
  return config;
});

export class RequestHelpers {
  constructor({ incrementLoading, decrementLoading }) {
    this.incrementLoading = incrementLoading;
    this.decrementLoading = decrementLoading;
  }

  get = async (url, params = {}, disableLoading) => {
    !disableLoading && this.incrementLoading();
    try {
      const response = await axiosInstance.get(url, {
        params,
      });
      return response.data;
    } catch (error) {
      throw handleRequestError(error);
    } finally {
      !disableLoading && this.decrementLoading();
    }
  };

  post = async (url, data = {}, options = {}, disableLoading) => {
    !disableLoading && this.incrementLoading();
    try {
      const response = await axiosInstance.post(url, data, options);
      return response.data;
    } catch (error) {
      throw handleRequestError(error);
    } finally {
      !disableLoading && this.decrementLoading();
    }
  };

  put = async (url, data = {}, options = {}, disableLoading) => {
    !disableLoading && this.incrementLoading();
    try {
      const response = await axiosInstance.put(url, data, options);
      return response.data;
    } catch (error) {
      throw handleRequestError(error);
    } finally {
      !disableLoading && this.decrementLoading();
    }
  };

  patch = async (url, data = {}, options = {}, disableLoading) => {
    !disableLoading && this.incrementLoading();
    try {
      const response = await axiosInstance.patch(url, data, options);
      return response.data;
    } catch (error) {
      throw handleRequestError(error);
    } finally {
      !disableLoading && this.decrementLoading();
    }
  };

  delete = async (url, options = {}, disableLoading) => {
    !disableLoading && this.incrementLoading();
    try {
      const response = await axiosInstance.delete(url, options);
      return response.data;
    } catch (error) {
      throw handleRequestError(error);
    } finally {
      !disableLoading && this.decrementLoading();
    }
  };

  setAuthorizationToken = (token) => {
    axiosInstance.interceptors.request.use(function (config) {
      //@ts-ignore
      config.headers = {
        ...config.headers,
        Authorization: token ? `Bearer ${token}` : "",
      };
      return config;
    });
  };
}

const handleRequestError = (error) => {
  console.error("Request error:", error);
  let errors = {};
  if (error.response) {
    // The request was made and the server responded with a status code
    errors.status = error.response.status;
    errors.data = error.response.data;

    console.error("Status:", error.response.status);
    console.error("Data:", error.response.data);
    console.error("Headers:", error.response.headers);

    if (
      error.response.status === 401 &&
      error?.response?.config?.method === "get" &&
      !KEY_IGNORE_VALIDATE?.includes(error.response.data.type)
    ) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.location.href = ROUTES.login;
      return;
    } else {
      return errors;
    }
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error setting up the request:", error.message);
    errors.message = error.message;
  }

  return errors;
};
