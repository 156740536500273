import { Badge } from "antd";
import React from "react";

import COLORS from "constants/colors";

import styles from "./styles.module.scss";

const BadgeStatus = ({ status, text }) => {
  let color = "";

  switch (status) {
    case "success":
      color = COLORS.success;
      break;

    case "danger":
      color = COLORS.danger;
      break;

    default:
      break;
  }

  return <Badge color={color} text={text} className={styles.container} />;
};

export default BadgeStatus;
