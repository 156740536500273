import { Image, Modal } from "antd";
import React, { useCallback, useState } from "react";

import errorImage from "assets/images/error.png";
import { CloseIcon } from "assets/icons";

import VerificationResult from "../VerificationResult";
import ScanQRAndVerify from "../ScanQRAndVerify";
import NotificationModal from "components/atoms/molecules/NotificationModal";

const ScanVerifyModal = ({ open, onCancel, onOpen }) => {
  const [type, setType] = useState("verify");
  const [openNotification, setOpenNotification] = useState(false);

  const handleClose = useCallback(() => {
    onCancel?.();
    setType("verify");
    setOpenNotification(false);
  }, [onCancel]);

  const renderLayout = useCallback(() => {
    switch (type) {
      case "verify":
        return (
          <ScanQRAndVerify
            onCancel={handleClose}
            onOpenNotificationChange={setOpenNotification}
            open={open}
            onTypeChange={setType}
          />
        );

      case "success":
        return <VerificationResult />;

      default:
        return <></>;
    }
  }, [handleClose, open, type]);

  const handleRetry = useCallback(() => {
    handleClose();
    onOpen?.();
  }, [handleClose, onOpen]);

  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={540}
        closeIcon={<CloseIcon />}
        className="my-8">
        <div className="mt-8">{renderLayout()}</div>
      </Modal>
      <NotificationModal
        open={openNotification}
        title="Scan & Verify"
        description="The user has rejected your request"
        onCancel={() => setOpenNotification(false)}
        image={
          <Image preview={false} src={errorImage} alt="Error" width={113} />
        }
        isCancel={false}
        onOk={handleRetry}
        okText="Try again"
      />
    </>
  );
};

export default ScanVerifyModal;
