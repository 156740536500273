import { Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";

import { FORMAT_DATE } from "constants/values";
import { useAppSelector } from "stores/stores";

import Card from "components/atoms/Card";
import InfoItem from "components/atoms/InfoItem";

const GeneralInformation = () => {
  const { credential } = useAppSelector((state) => state.issuer);

  const attributes = useMemo(
    () => credential?.attributes,
    [credential?.attributes]
  );
  const dataDetail = useMemo(
    () => [
      {
        label: "Passenger Name",
        value: attributes?.passengerName,
      },
      {
        label: "Flight Number",
        value: attributes?.flightNumber,
      },
      {
        label: "Departure Airport",
        value: attributes?.departureAirport,
      },
      {
        label: "Arrival Airport",
        value: attributes?.arrivalAirport,
      },
      {
        label: "Departure Time",
        value: dayjs(attributes?.departureTime).format(FORMAT_DATE),
      },
      {
        label: "Arrival Time",
        value: dayjs(attributes?.arrivalTime).format(FORMAT_DATE),
      },
      {
        label: "Seat Number",
        value: attributes?.seatNumber,
      },
      {
        label: "Gate Number",
        value: attributes?.gateNumber,
      },
      {
        label: "Boarding Time",
        value: dayjs(attributes?.boardingTime).format(FORMAT_DATE),
      },
      {
        label: "Class",
        value: attributes?.class,
      },
      {
        label: "Booking Reference",
        value: attributes?.bookingReference,
      },
      {
        label: "Frequent Flyer Number",
        value: attributes?.frequentflyerNumber,
      },
    ],
    [
      attributes?.arrivalAirport,
      attributes?.arrivalTime,
      attributes?.boardingTime,
      attributes?.bookingReference,
      attributes?.class,
      attributes?.departureAirport,
      attributes?.departureTime,
      attributes?.flightNumber,
      attributes?.frequentflyerNumber,
      attributes?.gateNumber,
      attributes?.passengerName,
      attributes?.seatNumber,
    ]
  );

  return (
    <Card title="General information">
      <Row gutter={[16, 16]}>
        {dataDetail?.map((record) => (
          <Col xs={24} lg={12} key={record?.value}>
            <InfoItem {...record} />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default GeneralInformation;
