import { Button } from "antd";
import React from "react";

import InfoItem from "components/atoms/InfoItem";

const ResultInfoItem = ({ title, subTitle, icon, infos, col = 1 }) => {
  return (
    infos && (
      <div className="flex flex-col gap-4 p-4 borer border-[1px] border-solid border-grey2 rounded-lg bg-grey5">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-semibold text-dark1">{title}</span>
            <span className="text-grey1 text-sm">{subTitle}</span>
          </div>
          <Button className="w-[50px] h-[50px] rounded-lg p-0 flex justify-center items-center bg-white">
            {icon}
          </Button>
        </div>
        <div
          className="grid gap-4"
          style={{
            gridTemplateColumns: `repeat(${col}, minmax(0, ${col}fr))`,
          }}>
          {infos?.map((record) => (
            <InfoItem key={record?.label} {...record} />
          ))}
        </div>
      </div>
    )
  );
};

export default ResultInfoItem;
