import COLORS from "constants/colors";
import React from "react";

export default function SignOutIcon(props) {
  const { color = COLORS.grey1 } = props;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M9 13.1667C9 13.9416 9 14.3291 8.91482 14.647C8.68365 15.5098 8.00978 16.1836 7.14705 16.4148C6.82913 16.5 6.44164 16.5 5.66667 16.5H5.25C4.08515 16.5 3.50272 16.5 3.04329 16.3097C2.43072 16.056 1.94404 15.5693 1.6903 14.9567C1.5 14.4973 1.5 13.9149 1.5 12.75V5.25C1.5 4.08515 1.5 3.50272 1.6903 3.04329C1.94404 2.43072 2.43072 1.94404 3.04329 1.6903C3.50272 1.5 4.08515 1.5 5.25 1.5H5.66667C6.44164 1.5 6.82913 1.5 7.14705 1.58519C8.00978 1.81635 8.68365 2.49022 8.91482 3.35295C9 3.67087 9 4.05836 9 4.83333"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
