import React from "react";

import Breadcrumb from "components/atoms/Breadcrumb";
import Button from "components/atoms/Button";

import styles from "./styles.module.scss";

const PageHeader = ({
  onAdd,
  addText,
  title,
  subTitle,
  addIcon,
  addProps,
  breadcrumbs,
}) => {
  return (
    <div className="flex items-center justify-between flex-col md:flex-row">
      {title && (
        <div className="flex flex-col gap-4 md:gap-1">
          <span className="text-dark1 text-xl font-semibold text-center md:text-left">
            {title}
          </span>
          <span className="text-grey1 text-xs text-center md:text-left">
            {subTitle}
          </span>
        </div>
      )}
      {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
      {addText && (
        <Button
          onClick={onAdd}
          icon={addIcon}
          className={`w-full md:w-fit flex-row-reverse gap-2 h-12 ${styles.button} mt-6 md:mt-0`}
          {...addProps}>
          {addText}
        </Button>
      )}
    </div>
  );
};

export default PageHeader;
