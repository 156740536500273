const COLORS = {
  primary: "#4660A4",
  dark1: "#160042",
  grey1: "#6B7194",
  grey2: "#E4E6F1",
  grey3: "#B0B0B0",
  grey4: "#B6B9CE",
  grey5: "#F7F8FD",
  blue1: "#0D6EFD",
  success: "#28A745",
  danger: "#DC3545",
  light1: "#EBEBF0",
  green1: "#28A74533",
};

export default COLORS;
