import { Menu as AntdMenu } from "antd";
import { CredentialIcon, VerificationIcon } from "assets/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";

import COLORS from "constants/colors";
import ROUTES from "constants/routes";
import { toCredentials, toVerifications } from "helpers/routing";

import styles from "./styles.module.scss";

const Menu = styled(AntdMenu)`
  && .ant-menu-item-selected {
    border-radius: 0px;
    border-right: 3px solid ${COLORS.blue} !important;
    width: 98.5%;
  }
`;

const MenuItems = () => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const routes = useMemo(
    () => [
      {
        key: ROUTES.verifications,
        icon: <VerificationIcon />,
        label: "Verifications",
        path: toVerifications({
          original: true,
        }),
      },
      {
        key: ROUTES.credentials,
        icon: <CredentialIcon />,
        label: "Credentials",
        path: toCredentials({
          original: true,
        }),
      },
    ],
    []
  );

  const menuItems = routes.map((route) => ({
    ...route,
    onClick: () => handleMenuClick(route.path),
  }));

  const handleMenuClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    setSelectedKeys(
      routes
        .filter((_item) => location.pathname.startsWith(_item.path))
        ?.map((record) => record.key)
    );
  }, [location, routes]);

  return (
    <Menu mode="inline" selectedKeys={selectedKeys} className={styles.menus}>
      {menuItems?.map((record) => (
        <Menu.Item
          key={`${record?.path}-${record?.label}`}
          className={cx({
            [styles.menus__selected]: selectedKeys?.includes(record?.path),
          })}
          icon={record?.icon}
          onClick={() => handleMenuClick(record?.path)}>
          {record?.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default MenuItems;
