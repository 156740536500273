import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { SignOutIcon } from "assets/icons";
import { ACCESS_TOKEN_KEY, EMAIL_KEY, NAME_KEY } from "constants/values";
import ROUTES from "constants/routes";

const UserInfo = () => {
  const history = useHistory();

  const handleLogout = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(EMAIL_KEY);
    localStorage.removeItem(NAME_KEY);
    history.push(ROUTES.login);
  }, [history]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col gap-1">
        <span className="text-dark1 text-sm font-medium">
          {localStorage.getItem(NAME_KEY)}
        </span>
        <span className="text-grey1 text-xs">
          {localStorage.getItem(EMAIL_KEY)}
        </span>
      </div>
      <div className="cursor-pointer" onClick={handleLogout}>
        <SignOutIcon />
      </div>
    </div>
  );
};

export default UserInfo;
