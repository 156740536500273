import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import React from "react";

const CheckInfo = ({ check, text }) => {
  return (
    <div className="flex items-center gap-1">
      {check ? (
        <CheckOutlined className="text-success" />
      ) : (
        <CloseOutlined className="text-danger" />
      )}
      <span className="text-dark1 text-base font-medium break-all">
        {text || "--"}
      </span>
    </div>
  );
};

export default CheckInfo;
