import React from "react";
import cx from "classnames";

const InfoItem = ({ label, value, isFull }) => {
  return (
    <div
      className={cx("flex flex-col md:gap-1", {
        "col-span-full": isFull,
      })}>
      <span className="text-grey1 text-sm">{label}</span>
      <span className="text-dark1 text-base font-medium break-all">
        {value}
      </span>
    </div>
  );
};

export default InfoItem;
