import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import store from "stores/stores";
import history from "helpers/history";

import App from "./App";

import "assets/styles/commons.scss";
import "assets/styles/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </>
);
