import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import dayjs from "dayjs";

import { CredentialIcon } from "assets/icons";

import { toCredentialDetail } from "helpers/routing";
import useBreakPoint from "hooks/useBreakpoint";
import { useAppDispatch, useAppSelector } from "stores/stores";
import usePagination from "hooks/usePagination";
import { getCredentials } from "stores/issuerSlice";
import useSort from "hooks/useSort";

import IssueBoardingPassModal from "./components/IssueBoardingPassModal";
import CredentialsList from "./components/CredentialsList";
import MainLayout from "components/layouts/MainLayout";
import PageHeader from "components/layouts/PageHeader";
import Table from "components/atoms/Table";

const CredentialsPage = () => {
  const dispatch = useAppDispatch();
  const { credentials, total, loading } = useAppSelector(
    (state) => state.issuer
  );

  const [openIssueBoardingPassModal, setOpenIssueBoardingPassModal] =
    useState(false);

  const { isMobile } = useBreakPoint();
  const { paginationFilter, onChangePage, page, pageSize, onChangePageSize } =
    usePagination({
      total,
    });
  const { sorter, onSortChange } = useSort({
    sortField: "createdAt",
    sortOrder: "DESC",
  });

  const columns = [
    {
      title: "Document ID",
      key: "id",
      dataIndex: "id",
      showSorterTooltip: { target: "full-header" },
      sorter: true,
      render: (_, record) => (
        <Link
          to={toCredentialDetail({
            id: record?.requestId,
            plain: true,
          })}
          component={Typography.Link}>
          {record?.requestId}
        </Link>
      ),
    },
    {
      title: "Credential schema",
      key: "credentialSchema",
      dataIndex: "credentialSchema",
    },
    {
      title: "Holder DID",
      key: "holderId",
      dataIndex: "holderId",
    },
    {
      title: "Created on",
      key: "createdOn",
      dataIndex: "createdOn",
      render: (value) => dayjs(value).format("DD-MM-YYYY"),
    },
  ];

  const handleTableChange = useCallback(
    (pagination, newPageSize, sort) => {
      if (pagination) {
        onChangePage?.(pagination);
      }

      if (sort) {
        onSortChange(sort);
      }

      if (newPageSize) {
        onChangePageSize?.(newPageSize);
      }
    },
    [onChangePage, onChangePageSize, onSortChange]
  );

  useEffect(() => {
    dispatch(getCredentials({ ...paginationFilter, ...sorter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationFilter, sorter]);

  return (
    <MainLayout>
      <PageHeader
        title="Credentials"
        subTitle="Each issued credential required the holder to claim by scanning a QR code"
        addText="Issue Boarding pass"
        addIcon={<CredentialIcon color="#fff" />}
        onAdd={() => setOpenIssueBoardingPassModal(true)}
        addProps={{
          loading,
        }}
      />
      <div className="mt-6">
        {isMobile ? (
          <CredentialsList />
        ) : (
          <Table
            columns={columns}
            data={credentials}
            onChange={handleTableChange}
            total={total}
            currentPage={page}
            pageSize={pageSize}
          />
        )}
      </div>
      <IssueBoardingPassModal
        open={openIssueBoardingPassModal}
        onCancel={() => setOpenIssueBoardingPassModal(false)}
      />
    </MainLayout>
  );
};

export default CredentialsPage;
