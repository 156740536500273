import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import issuerServices from "services/issuer.services";

const initialState = {
  credentials: [],
  credential: undefined,
  total: 0,
  loading: false,
  presentationId: "",
  verificationResult: undefined,
  verifications: undefined,
  verificationDetail: undefined,
};

export const getCredentials = createAsyncThunk(
  "issuer/getCredentials",
  async (params) => {
    try {
      const res = await issuerServices.getCredentials(params);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const getCredentialDetail = createAsyncThunk(
  "issuer/getCredentialDetail",
  async (id) => {
    try {
      const res = await issuerServices.getCredentialDetail(id);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const createIssueBoardingPass = createAsyncThunk(
  "issuer/createIssueBoardingPass",
  async (body) => {
    try {
      const res = await issuerServices.createIssueBoardingPass(body);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const revokeCredential = createAsyncThunk(
  "issuer/revokeCredential",
  async (id) => {
    try {
      const res = await issuerServices.revokeCredential(id);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const requestPresentation = createAsyncThunk(
  "issuer/requestPresentation",
  async (params) => {
    try {
      const res = await issuerServices.requestPresentation(params);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const getDetailPresentation = createAsyncThunk(
  "issuer/getDetailPresentation",
  async (params) => {
    try {
      const res = await issuerServices.getDetailPresentation(params);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const getVerifications = createAsyncThunk(
  "issuer/getVerifications",
  async (params) => {
    try {
      const res = await issuerServices.getVerifications(params);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const getCredentialResult = createAsyncThunk(
  "issuer/getCredentialResult",
  async (id) => {
    try {
      const res = await issuerServices.getCredentialResult(id);

      return res;
    } catch (error) {
      return null;
    }
  }
);

export const getVerificationDetail = createAsyncThunk(
  "issuer/getVerificationDetail",
  async (id) => {
    try {
      const res = await issuerServices.getVerificationDetail(id);

      return res;
    } catch (error) {
      return null;
    }
  }
);

const IssuerSlice = createSlice({
  name: "issuer",
  initialState,
  reducers: {
    setLoginForm: (state, action) => {
      state.loginForm = action.payload;
    },
    setPresentationId: (state, action) => {
      state.presentationId = action.payload;
    },
    setVerificationResult: (state, action) => {
      state.verificationResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCredentials.fulfilled, (state, action) => {
      if (action.payload) {
        state.credentials = action.payload?.items;
        state.total = action.payload?.totalItems;
      }
    });

    builder.addCase(getCredentialDetail.fulfilled, (state, action) => {
      if (action.payload) {
        state.credential = action.payload;
      }
    });

    builder.addCase(createIssueBoardingPass.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createIssueBoardingPass.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createIssueBoardingPass.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(revokeCredential.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(revokeCredential.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(revokeCredential.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(requestPresentation.fulfilled, (state, action) => {
      if (action.payload) {
        state.presentationId = action.payload;
      }
    });

    builder.addCase(getDetailPresentation.fulfilled, (state, action) => {
      if (action.payload) {
        state.verificationResult = action.payload?.infomation;
      }
    });

    builder.addCase(getVerifications.fulfilled, (state, action) => {
      if (action.payload) {
        state.verifications = action.payload;
      }
    });

    builder.addCase(getVerificationDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVerificationDetail.fulfilled, (state, { payload }) => {
      if (payload) {
        state.verificationDetail = payload;
      }
      state.loading = false;
    });
    builder.addCase(getVerificationDetail.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default IssuerSlice;
