import { Image } from "antd";
import React, { useMemo } from "react";

import { PhoneBookIcon } from "assets/icons";

import { useAppSelector } from "stores/stores";

import CheckInfo from "components/atoms/CheckInfo";
import ResultInfoItem from "components/atoms/ResultInfoItem";

const PassportInformation = () => {
  const { verificationDetail } = useAppSelector((state) => state.issuer);

  const passportInfos = useMemo(() => {
    if (!verificationDetail?.infomation?.passports?.length) {
      return;
    }

    return verificationDetail?.infomation?.passports?.map((record) => [
      {
        label: "Photo",
        value: (
          <Image
            preview={false}
            src={`data:image/png;base64, ${record?.photo}`}
            width={105}
            height={150}
          />
        ),
        isFull: true,
      },
      {
        label: "First name(s)",
        value: record?.firstName,
      },
      {
        label: "Last name",
        value: record?.lastName,
      },
      {
        label: "Date of birth",
        value: (
          <CheckInfo
            check={record?.dateOfBirth}
            text={record?.dateOfBirth ? "Above 18" : "Under 18"}
          />
        ),
      },
      {
        label: "Date of expiration",
        value: (
          <CheckInfo
            check={record?.dateOfExpiration}
            text={record?.dateOfExpiration ? "Valid" : "Invalid"}
          />
        ),
      },
    ]);
  }, [verificationDetail?.infomation?.passports]);

  return (
    passportInfos && (
      <div className="flex flex-col gap-6">
        {passportInfos?.map((record, index) => (
          <ResultInfoItem
            key={`passport-info-${index}`}
            title="Passport"
            subTitle="Basic Credential"
            icon={<PhoneBookIcon />}
            infos={record}
            col={2}
          />
        ))}
      </div>
    )
  );
};

export default PassportInformation;
