import React, { useCallback, useEffect, useState } from "react";
import QrReader from "react-qr-scanner";
import cx from "classnames";

import { useAppDispatch, useAppSelector } from "stores/stores";

import Loading from "components/atoms/Loading";
import { getDetailPresentation, requestPresentation } from "stores/issuerSlice";

const ScanQRAndVerify = ({
  onCancel,
  onOpenNotificationChange,
  open,
  onTypeChange,
}) => {
  const dispatch = useAppDispatch();
  const { presentationId } = useAppSelector((state) => state.issuer);

  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const handleError = useCallback(() => {
    onCancel?.();
    onOpenNotificationChange?.(true);
    setIsPending(false);
  }, [onCancel, onOpenNotificationChange]);

  const handleScan = useCallback(
    (data) => {
      if (data) {
        setLoading(true);

        dispatch(
          requestPresentation({
            did: data?.text,
          })
        );
      }
    },
    [dispatch]
  );

  const renderContent = useCallback(() => {
    if (isPending) {
      return (
        <div className="w-full h-[348px] bg-transparent border-success border-2 rounded-md flex justify-center items-center">
          <span className="text-success text-base font-medium">
            Pending holder’s approval...
          </span>
        </div>
      );
    }

    return (
      <QrReader
        onError={handleError}
        onScan={handleScan}
        className={cx("w-full rounded-md border-white border-solid border-2", {
          "h-[348px]": !isReady,
        })}
        delay={1000}
        onLoad={() => setIsReady(true)}
        constraints={{
          audio: false,
          video: { facingMode: "environment" },
        }}
      />
    );
  }, [handleError, handleScan, isPending, isReady]);

  const handleGetPresentation = useCallback(async () => {
    const { payload: response } = await dispatch(
      getDetailPresentation({
        id: presentationId,
      })
    );

    setLoading(false);

    if (response?.status === "Waiting For Approve") {
      setIsPending(true);
    } else if (!Object.keys(response?.infomation).length) {
      handleError();
    } else {
      onTypeChange?.("success");
    }
  }, [dispatch, handleError, onTypeChange, presentationId]);

  useEffect(() => {
    let autoRefreshGetPresentation;

    if (presentationId && open) {
      handleGetPresentation();

      autoRefreshGetPresentation = setInterval(async () => {
        handleGetPresentation();
      }, 10000);
    } else {
      clearInterval(autoRefreshGetPresentation);
    }

    return () => {
      clearInterval(autoRefreshGetPresentation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onOpenNotificationChange, onTypeChange, open, presentationId]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2 justify-center items-center">
        <span className="text-dark1 font-semibold text-2xl text-center">
          Scan & Verify
        </span>
        <span className="text-base text-grey1">
          Position the QR code of the DID holder in the frame
        </span>
      </div>
      <div
        className={cx("bg-dark1 p-[72px] rounded-md", {
          "bg-green1": loading || isPending,
          "bg-opacity-60": !loading,
        })}>
        {loading ? (
          <div className="w-full h-[348px] bg-transparent relative flex justify-center items-center m-auto">
            <Loading />
          </div>
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

export default ScanQRAndVerify;
