import React from "react";
import cx from "classnames";

const Card = ({ title, className, children }) => {
  return (
    <div
      className={cx("flex flex-col gap-6 p-6 border border-grey2 rounded-lg", {
        [className]: className,
      })}>
      {title && (
        <span className="text-dark1 text-xl font-semibold">{title}</span>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Card;
