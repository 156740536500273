import { useEffect, useState } from "react";

const useCountdown = ({
  seconds = 30,
  isTrigger: _isTrigger = true,
  onTimeoutCallback,
}) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [isTrigger, setTrigger] = useState(_isTrigger);

  useEffect(() => {
    if (isTrigger) {
      const intervalId = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isTrigger, seconds]);

  useEffect(() => {
    if (!timeLeft) {
      setTrigger(false);
      onTimeoutCallback?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return {
    timeLeft,
    onTimeLeftChange: setTimeLeft,
    onTriggerTimeout: setTrigger,
  };
};

export default useCountdown;
