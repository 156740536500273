import { useCallback, useMemo, useState } from "react";
import useBreakPoint from "./useBreakpoint";

const ITEMS_PER_PAGE = 10;

const usePagination = (pagination) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pagination?.limit ?? ITEMS_PER_PAGE);

  const { isMobile } = useBreakPoint();

  const paginationFilter = useMemo(() => {
    return {
      limit: isMobile ? 999 : pageSize,
      page: page.toString(),
    };
  }, [isMobile, page, pageSize]);

  const onChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const onChangePageSize = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  return {
    page,
    paginationFilter,
    pageCount: Math.ceil(
      (pagination?.total || 0) / (paginationFilter?.limit || 0)
    ),
    onChangePage,
    pageSize,
    onChangePageSize,
  };
};

export default usePagination;
