import { Form, Input } from "antd";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import useCountdown from "hooks/useCountdown";
import { useAppDispatch, useAppSelector } from "stores/stores";
import {
  loginWithVerificationCode,
  sendVerificationCode,
} from "stores/authSlice";
import ROUTES from "constants/routes";
import { DEFAULT_TIMEOUT, OTP_INPUT_ERROR_PROPS } from "./constants";

import Button from "components/atoms/Button";

import styles from "./styles.module.scss";

const OtpForm = () => {
  const dispatch = useAppDispatch();
  const { loginForm } = useAppSelector((state) => state.auth);

  const [isInvalidOtp, setIsInvalidOtp] = useState(false);

  const [form] = Form.useForm();

  const { timeLeft, onTimeLeftChange, onTriggerTimeout } = useCountdown({
    seconds: DEFAULT_TIMEOUT,
  });
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data) => {
      setIsInvalidOtp(false);

      const { payload } = await dispatch(
        loginWithVerificationCode({
          email: loginForm?.email,
          verifycode: data?.otp,
        })
      );

      if (payload) {
        history.push(ROUTES.verifications);
      } else {
        setIsInvalidOtp(true);
      }
    },
    [dispatch, history, loginForm?.email]
  );

  const handleResetVerificationCode = useCallback(() => {
    dispatch(
      sendVerificationCode({
        email: loginForm?.email,
      })
    );
    onTimeLeftChange(DEFAULT_TIMEOUT);
    onTriggerTimeout(true);
  }, [dispatch, loginForm?.email, onTimeLeftChange, onTriggerTimeout]);

  return (
    <Form
      form={form}
      name="validateOnly"
      layout="vertical"
      onFinish={handleSubmit}>
      <div className={styles.otp}>
        <Form.Item
          name="otp"
          rules={[
            {
              required: true,
              message: "Otp is required",
            },
          ]}
          {...(isInvalidOtp ? OTP_INPUT_ERROR_PROPS : {})}>
          <Input.OTP length={4} numeric={true} />
        </Form.Item>
      </div>
      <Form.Item>
        <div className="flex items-center justify-center md:justify-start">
          <span className="text-base text-grey1">
            Didn't receive a code?{" "}
            <button
              disabled={timeLeft}
              className={cx(
                "border-none shadow-none text-blue1 mx-1 cursor-pointer",
                {
                  "text-grey3 cursor-not-allowed": timeLeft,
                }
              )}
              onClick={handleResetVerificationCode}>
              Resend
            </button>{" "}
            in {timeLeft}s
          </span>
        </div>
      </Form.Item>
      <Button className="!h-[48px] !w-full" htmlType="submit">
        Log in
      </Button>
    </Form>
  );
};

export default OtpForm;
