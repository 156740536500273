import MainRoute from "pages";
import React from "react";
import { ToastContainer } from "react-toastify";

import { useAppSelector } from "stores/stores";

import Loading from "components/atoms/Loading";

const App = () => {
  const { counterLoading } = useAppSelector((state) => state.common);

  return (
    <React.Suspense fallback={<></>}>
      <MainRoute />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      {counterLoading !== 0 && (
        <div className="fixed top-0 left-0 w-full h-full bg-light1 opacity-70 flex justify-center items-center z-[1000]">
          <Loading loading={counterLoading !== 0} />
        </div>
      )}
    </React.Suspense>
  );
};

export default App;
