import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import ROUTES from "constants/routes";
import { toVerificationDetail } from "helpers/routing";
import { useAppDispatch } from "stores/stores";

import MainLayout from "components/layouts/MainLayout";
import PageHeader from "components/layouts/PageHeader";
import PassportInformation from "./components/PassportInformation";
import BoardingPassInformation from "./components/BoardingPassInformation";
import PresentationInformation from "./components/PresentationInformation";
import { getVerificationDetail } from "stores/issuerSlice";

const VerificationDetailPage = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const breadcrumbs = useMemo(
    () => [
      {
        title: "Presentation",
        href: ROUTES.verifications,
      },
      {
        title: id,
        href: toVerificationDetail({
          id,
          plain: true,
        }),
      },
    ],
    [id]
  );

  useEffect(() => {
    if (id) {
      dispatch(getVerificationDetail(id));
    }
  }, [dispatch, id]);

  return (
    <MainLayout hideFooter>
      <PageHeader breadcrumbs={breadcrumbs} />
      <div className="mt-6 flex gap-6 flex-col md:flex-row">
        <div className="flex flex-col gap-6 flex-1">
          <PassportInformation />
          <BoardingPassInformation />
        </div>
        <PresentationInformation />
      </div>
    </MainLayout>
  );
};

export default VerificationDetailPage;
