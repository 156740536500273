import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counterLoading: 0,
};

const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    incrementLoading: (state) => {
      state.counterLoading = state.counterLoading + 1;
    },
    decrementLoading: (state) => {
      if (state.counterLoading - 1 < 0) {
        state.counterLoading = 0;
      } else {
        state.counterLoading = state.counterLoading - 1;
      }
    },
  },
});

export default CommonSlice;
