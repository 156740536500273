import COLORS from "constants/colors";
import React from "react";

export default function PhoneBookIcon(props) {
  const { color = COLORS.dark1 } = props;

  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6665 23.3333V6.26667C1.6665 4.77319 1.6665 4.02646 1.95715 3.45603C2.21282 2.95426 2.62076 2.54631 3.12253 2.29065C3.69296 2 4.4397 2 5.93317 2H16.0665C17.56 2 18.3067 2 18.8771 2.29065C19.3789 2.54631 19.7869 2.95426 20.0425 3.45603C20.3332 4.02646 20.3332 4.77319 20.3332 6.26667V20.6667H4.33317C2.86041 20.6667 1.6665 21.8606 1.6665 23.3333ZM1.6665 23.3333C1.6665 24.8061 2.86041 26 4.33317 26H20.3332M18.9998 20.6667V26M14.9998 16C14.6217 14.4785 12.9745 13.3333 10.9998 13.3333C9.02517 13.3333 7.37802 14.4785 6.99984 16M10.9998 8H11.0132M12.3332 8C12.3332 8.73638 11.7362 9.33333 10.9998 9.33333C10.2635 9.33333 9.6665 8.73638 9.6665 8C9.6665 7.26362 10.2635 6.66667 10.9998 6.66667C11.7362 6.66667 12.3332 7.26362 12.3332 8Z"
        stroke={color}
        stroke-width="2.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
