import dayjs from "dayjs";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";

import { FORMAT_DATE } from "constants/values";
import { toCredentialDetail } from "helpers/routing";

import BadgeStatus from "components/atoms/BadgeStatus";
import Card from "components/atoms/Card";
import InfoItem from "components/atoms/InfoItem";
import { useAppSelector } from "stores/stores";

const CredentialsList = () => {
  const { credentials } = useAppSelector((state) => state.issuer);

  return (
    <div className="flex flex-col gap-4">
      {credentials.map((record, index) => {
        const infoList = [
          {
            label: "Document DID",
            value: (
              <Link
                to={toCredentialDetail({
                  id: record?.requestId,
                  plain: true,
                })}
                component={Typography.Link}>
                {record?.DIDdocumemt}
              </Link>
            ),
          },
          {
            label: "Credential schema",
            value: record?.credentialSchema,
          },
          {
            label: "Consent until",
            value: dayjs(record?.createdOn).format(FORMAT_DATE),
          },
          {
            label: "Status",
            value: <BadgeStatus status="successful" text="Valid" />,
          },
        ];

        return (
          <Card key={index}>
            <div className="flex flex-col gap-2">
              {infoList.map((record) => (
                <Fragment key={record?.value}>
                  <InfoItem {...record} />
                </Fragment>
              ))}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default CredentialsList;
