/* eslint-disable no-template-curly-in-string */
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Modal,
  QRCode,
  Row,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { CloseIcon } from "assets/icons";
import successImage from "assets/images/success.png";
import trashImage from "assets/images/trash.png";

import { useAppDispatch, useAppSelector } from "stores/stores";
import { DEFAULT_ERROR_MESSAGE, FORMAT_DATE_DISPLAY } from "constants/values";

import {
  createIssueBoardingPass,
  getCredentialResult,
  revokeCredential,
} from "stores/issuerSlice";

import Button from "components/atoms/Button";
import NotificationModal from "components/atoms/molecules/NotificationModal";
import InputField from "components/atoms/InputField";

import styles from "./styles.module.scss";

const IssueBoardingPassModal = ({ open, onCancel }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.issuer);

  const [openQRCodeModal, setOpenQRCodeModal] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openRevokedModal, setOpenRevokedModal] = useState(false);

  const [form] = Form.useForm();

  const handleClose = useCallback(() => {
    setOpenSuccessModal(false);
    setOpenRevokedModal(false);
    onCancel?.();
    form.resetFields();
  }, [form, onCancel]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const { payload } = await dispatch(createIssueBoardingPass(data));

        setRequestId(payload);
        handleClose();
        setOpenQRCodeModal(true);
      } catch (error) {
        toast.error(error?.message || DEFAULT_ERROR_MESSAGE);
      }
    },
    [dispatch, handleClose]
  );

  const handleGetCredentialResult = useCallback(async () => {
    const { payload } = await dispatch(getCredentialResult(requestId));

    switch (payload?.status) {
      case "Approved":
        setOpenQRCodeModal(false);
        setOpenSuccessModal(true);
        break;

      case "Revoked":
        setOpenRevokedModal(false);
        setOpenSuccessModal(true);
        break;

      default:
        break;
    }
  }, [dispatch, requestId]);
  const handleRevokeCredential = useCallback(async () => {
    await dispatch(revokeCredential(requestId));

    setOpenRevokedModal(false);
    handleClose();
  }, [dispatch, handleClose, requestId]);

  const handleCloseQRModal = useCallback(() => {
    handleClose();
    setOpenQRCodeModal(false);
  }, [handleClose]);

  useEffect(() => {
    let autoRefreshGetPresentation;

    if (requestId && openQRCodeModal) {
      handleGetCredentialResult();

      autoRefreshGetPresentation = setInterval(async () => {
        handleGetCredentialResult();
      }, 10000);
    } else {
      clearInterval(autoRefreshGetPresentation);
    }

    return () => {
      clearInterval(autoRefreshGetPresentation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openQRCodeModal, requestId]);

  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={handleClose}
        footer={() => (
          <div className="flex justify-center items-center gap-4 mt-6 mb-4">
            <Button
              variant="outlined"
              type="default"
              onClick={handleClose}
              className="w-full">
              Cancel
            </Button>
            <Button onClick={form.submit} className="w-full" loading={loading}>
              Issue
            </Button>
          </div>
        )}
        width={640}
        closeIcon={<CloseIcon />}
        className={styles.modal}>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          validateMessages={{
            required: "This information is required",
          }}
          className={styles.form}>
          <div className="flex flex-col justify-center items-center gap-2 my-6">
            <span className="text-dark1 font-semibold text-2xl">
              Issue boarding pass
            </span>
            <span className="text-grey1 text-base text-center">
              Enter the personal information of the Digital Travel Credential
              (DTC) holder
            </span>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="passengerName"
                label="Passenger Name"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="flightNumber"
                label="Flight Number"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="departureAirport"
                label="Departure Airport"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="arrivalAirport"
                label="Arrival Airport"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="departureTime"
                    label="Departure Time"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <DatePicker
                      className="w-full h-12"
                      showTime
                      format={FORMAT_DATE_DISPLAY}
                      minDate={getFieldValue("boardingTime")}
                      maxDate={getFieldValue("arrivalTime")}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item shouldUpdate dependencies={["departureTime"]}>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="arrivalTime"
                    label="Arrival Time"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <DatePicker
                      className="w-full h-12"
                      showTime
                      format={FORMAT_DATE_DISPLAY}
                      minDate={getFieldValue("departureTime")}
                      Tim
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="seatNumber"
                label="Seat Number"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="gateNumber"
                label="Gate Number"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item shouldUpdate dependencies={["departureTime"]}>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="boardingTime"
                    label="Boarding Time"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <DatePicker
                      className="w-full h-12"
                      showTime
                      format={FORMAT_DATE_DISPLAY}
                      maxDate={getFieldValue("departureTime")}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="class"
                label="Class"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="bookingReference"
                label="Booking Reference"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="frequentflyerNumber"
                label="Frequent Flyer Number">
                <InputField />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider className="bg-grey2" />
      </Modal>
      <NotificationModal
        open={openQRCodeModal}
        title="Boarding pass ready to be claimed"
        description="Please present the above QR-code for the traveler to claim their Digital Boarding pass."
        onCancel={handleCloseQRModal}
        isOk={false}
        isCancel={false}
        image={<QRCode value={requestId} size={200} />}
      />
      <NotificationModal
        open={openSuccessModal}
        title="Credential claimed"
        description="The traveler successfully claimed the credential"
        onCancel={handleClose}
        isOk={false}
        cancelText="Close"
        image={<Image preview={false} src={successImage} />}
      />
      <NotificationModal
        open={openRevokedModal}
        title="Revoking credential"
        description="Revoking this credential will permanently delete it from the user's Liquid wallet and Liquid portal"
        cancelText="Cancel"
        okText="Revoking"
        okProps={{
          type: "danger",
        }}
        onOk={handleRevokeCredential}
        onCancel={() => setOpenRevokedModal(false)}
        image={
          <Image preview={false} src={trashImage} alt="Trash" width={160} />
        }
      />
    </>
  );
};

export default IssueBoardingPassModal;
