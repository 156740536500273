import React from "react";
import { ClipLoader } from "react-spinners";

import COLORS from "constants/colors";

const Loading = ({
  size = 52,
  color = COLORS.primary,
  loading = true,
  cssOverride = {
    display: "block",
  },
}) => {
  return (
    <div className="animate-spin">
      <ClipLoader
        cssOverride={cssOverride}
        size={size}
        color={color}
        loading={loading}
      />
    </div>
  );
};

export default Loading;
