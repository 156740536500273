import END_POINTS from "constants/endpoints";
import { replaceKeyEndpoint } from "helpers/commons";
import requestHelpers from "helpers/request-helper";

const issuerServices = {
  getCredentials: async (params) => {
    return requestHelpers.get(END_POINTS.getCredentials, params);
  },
  getCredentialDetail: async (id) => {
    return requestHelpers.get(
      replaceKeyEndpoint(END_POINTS.getCredentialDetail, id)
    );
  },
  createIssueBoardingPass: async (body) => {
    return requestHelpers.post(END_POINTS.createIssueBoardingPass, body);
  },
  revokeCredential: async (id) => {
    return requestHelpers.get(
      END_POINTS.revokeCredential.replaceAll("{id}", id)
    );
  },
  requestPresentation: async (params) => {
    return requestHelpers.get(END_POINTS.requestPresentation, params);
  },
  getDetailPresentation: async (params) => {
    return requestHelpers.get(END_POINTS.getDetailPresentation, params);
  },
  getVerifications: async (params) => {
    return requestHelpers.get(END_POINTS.getVerifications, params);
  },
  getCredentialResult: async (id) => {
    return requestHelpers.get(
      END_POINTS.getCredentialResult.replaceAll("{id}", id)
    );
  },
  getVerificationDetail: async (id) => {
    return requestHelpers.get(
      END_POINTS.getVerificationDetail.replaceAll("{id}", id)
    );
  },
};

export default issuerServices;
