import React from "react";

import useBreakPoint from "hooks/useBreakpoint";

import DesktopLayout from "../DesktopLayout";
import MobileLayout from "../MobileLayout";

const MainLayout = ({ children, ...rest }) => {
  const { isMobile } = useBreakPoint();

  return isMobile ? (
    <MobileLayout {...rest}>{children}</MobileLayout>
  ) : (
    <DesktopLayout>{children}</DesktopLayout>
  );
};

export default MainLayout;
