import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import CommonSlice from "./commonSlice";
import AuthSlice from "./authSlice";
import IssuerSlice from "./issuerSlice";

const rootReducer = {
  common: CommonSlice.reducer,
  auth: AuthSlice.reducer,
  issuer: IssuerSlice.reducer,
};

const store = configureStore({ reducer: rootReducer });

export const useAppDispatch = () => useDispatch();

export const useAppSelector = useSelector;

export default store;
