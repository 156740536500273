import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthorizedRouter = ({
  component: Component,
  layout: Layout,
  isAuthProtected = true,
  authPath,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected) {
          <Redirect to={{ pathname: authPath }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AuthorizedRouter;
