import { Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";

import { FORMAT_DATE } from "constants/values";
import { useAppSelector } from "stores/stores";
import { formatCredentialStatus } from "helpers/commons";

import Card from "components/atoms/Card";
import InfoItem from "components/atoms/InfoItem";
import BadgeStatus from "components/atoms/BadgeStatus";

const DocumentInformation = () => {
  const { credential } = useAppSelector((state) => state.issuer);

  const dataDetail = useMemo(
    () => [
      {
        label: "Document status",
        value: (
          <BadgeStatus
            status={formatCredentialStatus(credential?.documentStatus)}
            text={credential?.documentStatus}
          />
        ),
      },
      {
        label: "Credential ID",
        value: credential?.credentialDID,
      },
      {
        label: "Credential schema",
        value: credential?.credentialSchema,
      },
      {
        label: "Holder DID",
        value: credential?.holderDid,
      },
      {
        label: "Created on",
        value: dayjs(credential?.createdOn).format(FORMAT_DATE),
      },
    ],
    [
      credential?.createdOn,
      credential?.credentialDID,
      credential?.credentialSchema,
      credential?.documentStatus,
      credential?.holderDid,
    ]
  );

  return (
    <Card
      title="Document information"
      className="min-w-[320px] w-fit h-fit flex-1">
      <Row gutter={[16, 16]}>
        {dataDetail?.map((record) => (
          <Col span={24} key={record?.value}>
            <InfoItem {...record} />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default DocumentInformation;
