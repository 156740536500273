import { Layout } from "antd";
import React from "react";

import MenuItems from "components/atoms/molecules/MenuItems";

const { Content, Footer } = Layout;

const MobileLayout = ({ children, hideFooter }) => {
  return (
    <Layout className="min-h-screen bg-grey5">
      <Content className="p-6 mb-10">{children}</Content>
      {!hideFooter && (
        <Footer className="p-0 fixed bottom-0 w-full">
          <MenuItems />
        </Footer>
      )}
    </Layout>
  );
};

export default MobileLayout;
