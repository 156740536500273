import { Button, Card, Flex, Image } from "antd";
import React from "react";
import cx from "classnames";

import liquidLogoImage from "assets/images/liquid_logo.png";
import { ArrowLeftOutlined } from "@ant-design/icons";

const AuthorizedLayout = ({
  children,
  title,
  description,
  hideLogo,
  className,
  action,
  center,
  isBack,
  onBack,
}) => {
  return (
    <Flex
      vertical
      gap={64}
      justify="center"
      align="center"
      className="h-screen">
      {!hideLogo && (
        <Image preview={false} src={liquidLogoImage} width={160} height={54} />
      )}
      <div
        className={cx("flex flex-col gap-4", {
          [className]: className,
        })}>
        {action}
        <Card className="border-grey2 shadow-shadow1">
          {isBack && (
            <Button
              icon={<ArrowLeftOutlined />}
              className="mb-6 !border-grey4 !text-dark1 !px-[10px] !py-[6px] !h-auto"
              onClick={onBack}>
              Back
            </Button>
          )}
          <div className="flex flex-col gap-6">
            <div
              className={cx("flex flex-col gap-4", {
                "justify-center items-center": center,
              })}>
              <span
                className={cx("text-dark1 text-2xl font-semibold", {
                  "text-center": center,
                })}>
                {title}
              </span>
              <span
                className={cx("text-grey1 text-base", {
                  "text-center": center,
                })}>
                {description}
              </span>
            </div>
            {children}
          </div>
        </Card>
      </div>
    </Flex>
  );
};

export default AuthorizedLayout;
