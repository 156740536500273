import COLORS from "constants/colors";
import React from "react";

export default function EmailIcon(props) {
  const { color = COLORS.grey1 } = props;

  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 3.66675L6.0416 6.69448C7.11079 7.40727 7.64539 7.76367 8.22321 7.90214C8.73384 8.0245 9.26616 8.0245 9.77679 7.90214C10.3546 7.76367 10.8892 7.40727 11.9584 6.69448L16.5 3.66675M4.16667 12.8334H13.8333C14.7668 12.8334 15.2335 12.8334 15.59 12.6518C15.9036 12.492 16.1586 12.237 16.3183 11.9234C16.5 11.5669 16.5 11.1002 16.5 10.1667V3.83341C16.5 2.89999 16.5 2.43328 16.3183 2.07676C16.1586 1.76316 15.9036 1.50819 15.59 1.3484C15.2335 1.16675 14.7668 1.16675 13.8333 1.16675H4.16667C3.23325 1.16675 2.76654 1.16675 2.41002 1.3484C2.09641 1.50819 1.84144 1.76316 1.68166 2.07676C1.5 2.43328 1.5 2.89999 1.5 3.83341V10.1667C1.5 11.1002 1.5 11.5669 1.68166 11.9234C1.84144 12.237 2.09641 12.492 2.41002 12.6518C2.76654 12.8334 3.23325 12.8334 4.16667 12.8334Z"
        stroke={color}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
