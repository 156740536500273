import React, { useMemo } from "react";
import dayjs from "dayjs";
import { Col, Row } from "antd";

import { useAppSelector } from "stores/stores";
import { formatCredentialStatus } from "helpers/commons";
import { FORMAT_DATE_FULL } from "constants/values";

import BadgeStatus from "components/atoms/BadgeStatus";
import InfoItem from "components/atoms/InfoItem";
import Card from "components/atoms/Card";

const PresentationInformation = () => {
  const { verificationDetail } = useAppSelector((state) => state.issuer);

  const dataDetail = useMemo(
    () => [
      {
        label: "Verification result",
        value: (
          <BadgeStatus
            status={formatCredentialStatus(verificationDetail?.status)}
            text={verificationDetail?.status}
          />
        ),
      },
      {
        label: "Verification ID",
        value: verificationDetail?.id,
      },
      {
        label: "Verification date & time",
        value: dayjs(verificationDetail?.verificationDateAndTime).format(
          FORMAT_DATE_FULL
        ),
      },
    ],
    [
      verificationDetail?.id,
      verificationDetail?.status,
      verificationDetail?.verificationDateAndTime,
    ]
  );

  return (
    <Card title="Verification information" className="w-[320px] h-fit">
      <Row gutter={[16, 16]}>
        {dataDetail?.map((record) => (
          <Col span={24} key={record?.value}>
            <InfoItem {...record} />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default PresentationInformation;
