import React, { useMemo } from "react";
import { Image } from "antd";

import { PhoneBookIcon, PlaneIcon } from "assets/icons";

import { useAppSelector } from "stores/stores";

import CheckInfo from "components/atoms/CheckInfo";
import ResultInfoItem from "components/atoms/ResultInfoItem";

const VerificationResult = () => {
  const { verificationResult } = useAppSelector((state) => state.issuer);

  const passportInfos = useMemo(() => {
    if (!verificationResult?.passports?.length) {
      return;
    }

    return verificationResult?.passports?.map((record) => [
      {
        label: "Photo",
        value: (
          <Image
            preview={false}
            src={`data:image/png;base64, ${record?.photo}`}
            width={105}
            height={150}
          />
        ),
      },
      {
        label: "First name(s)",
        value: record?.firstName,
      },
      {
        label: "Last name",
        value: record?.lastName,
      },
      {
        label: "Date of birth",
        value: (
          <CheckInfo
            check={record?.dateOfBirth}
            text={record?.dateOfBirth ? "Above 18" : "Under 18"}
          />
        ),
      },
      {
        label: "Date of expiration",
        value: (
          <CheckInfo
            check={record?.dateOfExpiration}
            text={record?.dateOfExpiration ? "Valid" : "Invalid"}
          />
        ),
      },
    ]);
  }, [verificationResult?.passports]);
  const boardingPassInfos = useMemo(() => {
    if (!verificationResult?.boardingpasses?.length) {
      return;
    }

    return verificationResult?.boardingpasses?.map((record) => [
      {
        label: "Passenger name",
        value: record?.passengerName,
      },
      {
        label: "Departure Airport",
        value: record?.fromAirportCode,
      },
      {
        label: "Arrival airport",
        value: record?.toAirportCode,
      },
      {
        label: "Flight Number",
        value: record?.flightNumber,
      },
    ]);
  }, [verificationResult?.boardingpasses]);

  return (
    <div className="flex flex-col gap-6">
      <span className="text-dark1 font-semibold text-2xl text-center">
        Verification result
      </span>
      {passportInfos && (
        <div className="flex flex-col gap-6">
          {passportInfos?.map((record, index) => (
            <ResultInfoItem
              key={`passport-info-${index}`}
              title="Passport"
              subTitle="Basic Credential"
              icon={<PhoneBookIcon />}
              infos={record}
            />
          ))}
        </div>
      )}
      {boardingPassInfos && (
        <div className="flex flex-col gap-6">
          {boardingPassInfos?.map((record, index) => (
            <ResultInfoItem
              key={`boarding-pass-info-${index}`}
              title="Boarding Pass"
              subTitle="Basic Credential"
              icon={<PlaneIcon />}
              infos={record}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default VerificationResult;
