const END_POINTS = {
  sendVerificationCode: "/auth/send-verify-code-partal",
  loginWithVerificationCode: "/auth/partal-login",
  getCredentials: "/Issuer/view-credential",
  getCredentialDetail: "/Issuer/view-credential-detail/{id}",
  createIssueBoardingPass: "/Issuer/offer-credential",
  revokeCredential: "/Issuer/revoke/{id}",
  requestPresentation: "/verifier/request-presentation",
  getDetailPresentation: "/verifier/get-detail-presentation",
  getVerifications: "/verifier/get-presentation",
  getVerificationDetail: "/verifier/get-detail-presentation?id={id}",
  getCredentialResult: "/Issuer/credential-status/{id}",
};

export default END_POINTS;
