import React from "react";
import cx from "classnames";
import { Button as AntdButton } from "antd";

import styles from "./styles.module.scss";

const Button = ({
  variant = "contained",
  type = "primary",
  className,
  children,
  loading,
  ...rest
}) => {
  return (
    <AntdButton
      loading={loading}
      className={cx(
        `flex items-center justify-center gap-2 text-base font-semibold ${styles.container}`,
        {
          [styles.container__contained]: variant === "contained",
          [styles.container__outlined]: variant === "outlined",
          [styles.container__primary]: type === "primary",
          [styles.container__danger]: type === "danger",
          [className]: className,
        }
      )}
      {...rest}>
      {children}
    </AntdButton>
  );
};

export default Button;
