import { Image, Layout } from "antd";
import React from "react";

import liquidLogoImage from "assets/images/liquid_logo.png";

import MenuItems from "components/atoms/molecules/MenuItems";
import UserInfo from "components/atoms/molecules/UserInfo";

const { Sider, Content } = Layout;

const DesktopLayout = ({ children }) => {
  return (
    <Layout className="min-h-screen bg-grey5">
      <Sider
        theme="light"
        width={256}
        trigger={null}
        className="p-0 w-full border-r-[1px] border-grey2">
        <div className="flex flex-col gap-4 h-full">
          <div className="my-8 mx-6">
            <Image
              preview={false}
              src={liquidLogoImage}
              width={140}
              height={44}
            />
          </div>
          <MenuItems />
          <div className="px-6 mt-auto pb-8 pt-6 border-t-[1px] border-grey2">
            <UserInfo />
          </div>
        </div>
      </Sider>
      <Content className="p-8">{children}</Content>
    </Layout>
  );
};

export default DesktopLayout;
