import dayjs from "dayjs";
import React, { Fragment } from "react";

import { FORMAT_DATE_FULL } from "constants/values";
import { useAppSelector } from "stores/stores";
import { formatVerificationStatus } from "helpers/commons";

import BadgeStatus from "components/atoms/BadgeStatus";
import Card from "components/atoms/Card";
import InfoItem from "components/atoms/InfoItem";

const VerificationsList = () => {
  const { verifications } = useAppSelector((state) => state.issuer);

  return (
    <div className="flex flex-col gap-4">
      {verifications?.data?.map((record) => (
        <Card key={record?.id}>
          {[
            {
              label: "Verification date & time",
              value: dayjs(record?.verificationDateAndTime).format(
                FORMAT_DATE_FULL
              ),
            },
            {
              label: "Credential DID",
              value: record?.id,
            },
            {
              label: "Veritifcation result",
              value: (
                <BadgeStatus
                  status={formatVerificationStatus(record?.status)}
                  text={record?.status}
                />
              ),
            },
          ].map((record) => (
            <Fragment key={record?.value}>
              <InfoItem {...record} />
            </Fragment>
          ))}
        </Card>
      ))}
    </div>
  );
};

export default VerificationsList;
