import { Modal } from "antd";
import Button from "components/atoms/Button";
import React from "react";

const NotificationModal = ({
  open,
  onOk,
  onCancel,
  cancelText,
  okText,
  okProps,
  image,
  title,
  description,
  isOk = true,
  isCancel = true,
}) => {
  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={() => (
        <div className="flex justify-center items-center gap-4 mt-12">
          {isCancel && (
            <Button
              variant="outlined"
              type="default"
              onClick={onCancel}
              className="w-full">
              {cancelText}
            </Button>
          )}

          {isOk && (
            <Button onClick={onOk} className="w-full" {...okProps}>
              {okText}
            </Button>
          )}
        </div>
      )}>
      <div className="flex flex-col gap-6 mt-6">
        {image && <div className="m-auto">{image}</div>}
        <div className="flex flex-col gap-2">
          <span className="text-dark2 text-2xl font-semibold text-center">
            {title}
          </span>
          <span className="text-grey1 text-base text-center">
            {description}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
