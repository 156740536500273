export const replaceKeyEndpoint = (endPoint, value, key = "id") => {
  return endPoint?.replaceAll(`{${key}}`, value);
};

export const formatCredentialStatus = (status) => {
  switch (status) {
    case "Valid":
    case "Approved":
      return "success";

    default:
      return "danger";
  }
};

export const protectSecretValue = (value = "", length = 13) => {
  return `${value.toString().substring(0, length)}......
  ${value.toString().substring(value.length - length, value.length)}`;
};

export const formatVerificationStatus = (status) => {
  switch (status) {
    case "Successful":
      return "success";

    default:
      return "danger";
  }
};
