import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  toCredentialDetail,
  toCredentials,
  toLogin,
  toVerificationDetail,
  toVerifications,
} from "helpers/routing";

import AuthorizedRouter from "components/layouts/AuthorizedRouter";
import LoginPage from "./login";
import CredentialsPage from "./credentials";
import CredentialDetailPage from "./credential-detail";
import VerificationsPage from "./verifications";
import VerificationDetailPage from "./verification-detail";

const MainRoute = () => {
  return (
    <React.Suspense fallback={<></>}>
      <Switch>
        <AuthorizedRouter
          isAuthProtected={false}
          exact
          path={toLogin({
            original: true,
          })}
          component={LoginPage}
        />
        <AuthorizedRouter
          exact
          path={toVerifications({
            original: true,
          })}
          component={VerificationsPage}
        />
        <AuthorizedRouter
          exact
          path={toVerificationDetail({
            original: true,
          })}
          component={VerificationDetailPage}
        />
        <AuthorizedRouter
          exact
          path={toCredentialDetail({
            original: true,
          })}
          component={CredentialDetailPage}
        />
        <AuthorizedRouter
          exact
          path={toCredentials({
            original: true,
          })}
          component={CredentialsPage}
        />
        <Route
          exact
          path="/"
          render={() => (
            <Redirect
              to={toVerifications({
                original: true,
              })}
            />
          )}
        />
      </Switch>
    </React.Suspense>
  );
};

export default MainRoute;
