import COLORS from "constants/colors";
import React from "react";

export default function VerificationIcon(props) {
  const { color = COLORS.grey1 } = props;

  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9.99987L8 11.9999L12 7.99987M17 9.99987C17 14.461 11.54 17.6936 9.64142 18.6828C9.43607 18.7898 9.33339 18.8433 9.19095 18.8711C9.08 18.8926 8.92 18.8926 8.80905 18.8711C8.66661 18.8433 8.56393 18.7898 8.35858 18.6828C6.45996 17.6936 1 14.461 1 9.99987V6.21747C1 5.41796 1 5.0182 1.13076 4.67457C1.24627 4.37101 1.43398 4.10015 1.67766 3.8854C1.9535 3.64231 2.3278 3.50195 3.0764 3.22122L8.4382 1.21054C8.6461 1.13258 8.75005 1.0936 8.85698 1.07815C8.95184 1.06444 9.04816 1.06444 9.14302 1.07815C9.24995 1.0936 9.3539 1.13258 9.5618 1.21054L14.9236 3.22122C15.6722 3.50195 16.0465 3.64231 16.3223 3.8854C16.566 4.10015 16.7537 4.37101 16.8692 4.67457C17 5.0182 17 5.41796 17 6.21747V9.99987Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
